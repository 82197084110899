import { navigate, graphql, PageProps } from 'gatsby';
import Layout from '../layout';
import backgroundImg from "../images/fotos/RepeatBg.png";
import { BackgroundOverlay } from '../components/BackgroundOverlay/BackgroundOverlay';
import { BackgroundVideo } from '../components/BackgroundVideo/BackgroundVideo';
import ZiggoVodafoneLogo from '../images/fotos/vodafoneziggo-white.png';
import PhoneIcon from "../images/icons/phone.svg";
import EmailIcon from "../images/icons/email-icon.svg";

import ReactTooltip from 'react-tooltip';
// @ts-ignore
import Fade from 'react-reveal/Fade';
import React from 'react';
import _ from 'lodash';

import "react-multi-carousel/lib/styles.css";
import { mq } from '../theme';
import { Text } from '../components/Text/Text';
import styled from '@emotion/styled';
import { getWithExpiry, setWithExpiry } from '../lib/local-storage-with-expiry';

// @ts-ignore
import coreuiCss from '!raw-loader!../layout/coreui.css'
import Helmet from 'react-helmet';
import Button, { EButtonType } from '../components/Button';
import { api } from '../lib/api';

class CompanyFormPage extends React.PureComponent<PageProps<{ }>, { }> {
  sessionOrder: any;

  constructor(props: any) {
    super(props);

    this.sessionOrder = getWithExpiry('order') || {};
  }


  public render() {
    const video = this.props.data.file;   
    
    return (
      <Layout>
        <Helmet>
          <style>{coreuiCss}</style>
        </Helmet>
        <ReactTooltip
          id="form"
          html={true}
        />
        <div 
          css={mq({
            position: 'relative', 
            paddingBottom: [18, null, 235],
            backgroundImage: `url('${backgroundImg}')`
          })}
        >
          <section
            css={mq({
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
              gap: 24,
              alignItems: 'end',
              position: 'relative',
              maxWidth: 1080,
              margin: '0 auto',
              zIndex: 1,
              paddingTop: 140,
              paddingLeft: 12,
              paddingRight: 12,
            })}
          >
            <div>
              <div            
                css={{
                  position: 'absolute',
                  top: 10,
                  left: 0,
                  color: '#fff',
                }}
              >
                <Text onDark css={{ marginLeft: 14, opacity: 0.6 }} size="regular">Partner van:</Text>
                <br />
                <img
                  src={ZiggoVodafoneLogo} 
                />
              </div>              
            </div>
          </section>
          <BackgroundOverlay
            css={mq({
              position: 'absolute',
              left: 0,
              right: 0,
              top: 0,
              height: 660,
            })}
          >
            <BackgroundVideo 
              css={mq({
                height: 660,
              })}
              posterUrl={video.videoScreenshots[0].publicURL}
              video={{
                H264: video.videoH264,
                H265: video.videoH265,
                VP9: video.videoVP9
              }}
            />
          </BackgroundOverlay>
          <div
            css={mq({
              paddingLeft: [6, 12, 24],
              paddingRight: [6, 12, 24],
            })}
          >
            <div
              css={{
                background: '#fff',
                position: 'relative',
                zIndex: 1,
                maxWidth: 1080,
                margin: '0 auto',
                marginTop: 32,
                boxShadow: '-2px 10px 41px -21px rgba(0,0,0,0.75)',
                paddingTop: 20,
                paddingBottom: 18,
              }}
            >
            
              <div
                css={(theme: any)=> mq({
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: theme.colors.primary,
                  minHeight: 80,
                  marginLeft: [-6, null, -18],
                  marginRight: [-6, null, -18],
                  paddingLeft: 24,
                })}
              >
                <Text
                  onDark
                  size="xl"
                  css={{
                    fontWeight: 300
                  }}
                >
                  Bedankt voor uw aanvraag
                </Text>
              </div>
              <div
                css={(theme: any) => mq({
                  padding: 16,
                  maxWidth: ['100%', null, '65%']
                })}
              >
                Uw abonnement aanvraag is ontvangen. We nemen zo spoedig mogelijk contact met u op.
                <br /><br />
                Mocht u tussentijds al vragen hebben kunt u contact op nemen via de volgende kanalen:     
                <a href="tel:0302498181">         
                  <Button css={mq({
                    fontSize:16,
                    marginTop: 16,
                  })}
                    type={EButtonType.WHITE} 
                    callToAction 
                    callToActionIcon={<PhoneIcon css={{height: 14, marginRight: 4, top: 4}}/>} 
                  >
                    030 249 8181
                  </Button>  
                </a>      
                <a href="mailto:backoffice@verlengafdeling.nl">
                  <Button css={mq({
                    fontSize:16,
                    marginTop: 8,
                  })}
                    type={EButtonType.WHITE} 
                    callToAction 
                    callToActionIcon={<EmailIcon css={{height: 16, marginRight: 4, top: 3}}/>} 
                  >
                    backoffice@verlengafdeling.nl
                  </Button>
                </a>     
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}


export const query = graphql`
  query OfferteQuery {
    file(relativePath: { eq: "video/vodafone-video.mp4" }) {
      id
      name
      videoH264 {
        path
      }
      videoH265 {
        path
      }
      videoVP9 {
        path
      }
      videoScreenshots(timestamps: ["0", "1", "50%", "99%"]) {
        publicURL
      }
    }
  }
`;

export default CompanyFormPage;
